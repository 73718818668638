<template>
  <div>
    <base-upload
      :on-exceeded-size="handleMaxSize"
      ref="base"
      @input="(val)=>{$emit('input',val)}"
      :value="value"
      :maxSize="maxSize"
      :max="max"
      :beforeUpload="beforeUpload"
      :url="url"
    >
      <template v-slot:default="{uploadList}">
        <div style="max-width:100%" :key="index" v-for="(item,index) in uploadList">
          <template v-if="item.status === 'finished'">
            <p :title="item.name" class="file-name">
              {{item.name}}
              <Icon @click="handleRemove(item)" class="close-icon" type="md-close" color="red" />
            </p>
          </template>
          <template v-else>
            <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
          </template>
        </div>
      </template>
      <template #button>
        <Icon class="upload-button" type="md-cloud-upload" color="#446ba1" />
      </template>
    </base-upload>
  </div>
</template>
<script>
import baseUpload from "./base_upload";
import API, { V1 } from "@/common/http/api";

function dataURItoBlob(base64Data) {
  let byteString;
  if (base64Data.split(",")[0].indexOf("base64") >= 0)
    byteString = window.atob(base64Data.split(",")[1]);
  else byteString = window.decodeURI(base64Data.split(",")[1]);
  let mimeString = base64Data
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export default {
  components: {
    baseUpload
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    // 传入图片最大值，单位为 M ,超过将被压缩
    maxSize: {
      type: Number,
      default: 4
    },
    max: Number
  },
  data() {
    return {
      url: V1 + API.UPLOAD.FILE
    };
  },
  methods: {
    beforeUpload(file, fileList) {
      if (file.size > 1024 * 1024 * this.maxSize) {
        let URL = window.URL || window.webkitURL;
        let objecturl = URL.createObjectURL(file);
        let img = document.createElement("img");
        img.src = objecturl;
        let _self = this;
        return new Promise((resolve, reject) => {
          img.onload = function() {
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            let scale = ((1024 * 1024 * _self.maxSize) / file.size).toFixed(6);
            let canvas = document.createElement("canvas");
            canvas.width = width * scale;
            canvas.height = height * scale;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(
              img,
              0,
              0,
              width,
              height,
              0,
              0,
              width * scale,
              height * scale
            );
            let image_url = canvas.toDataURL(file.type, 1);
            let blob = dataURItoBlob(image_url);
            let newFile = new File([blob], file.name, { type: file.type });
            resolve(newFile);
          };
        });
      } else return true;
    },
    clearFiles() {
      this.$refs.upload.clearFiles();
    },
    handleRemove(file) {
      this.$refs.base.handleRemove(file);
    },
    handleMaxSize() {
      this.$Message.warning(`文件不要超过${this.maxSize}kb`);
    }
  }
};
</script>
<style lang="less" scoped>
.upload-button {
  font-size: 24px;
  cursor: pointer;
}
.file-name {
  background: rgba(204, 204, 204, 0.2);
  padding: 0 10px 0 5px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  .close-icon {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    .close-icon {
      display: block;
    }
  }
}
</style>
